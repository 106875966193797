<template>
  <div class='container-fluid'>
    <div class='row row--grid'>
      <!-- breadcrumb -->
      <div class='col-12'>
        <ul class='breadcrumb'>
          <li class='breadcrumb__item'>
            <router-link to='/'>홈</router-link>
          </li>
          <li class='breadcrumb__item breadcrumb__item--active'>개인정보보호</li>
        </ul>
      </div>
      <!-- end breadcrumb -->

      <!-- title -->
      <div class='col-12'>
        <div class='main__title privacy-text'>
          <p class='ls2 lh6 bs5 ts4'><em class='emphasis'>[코딩티딩]('https://ailearn.space'이하 'AI Teading')</em>은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</p>
          <p class='ls2'></p>
          <br/>
          <p class='sub_p mgt30'><strong>제1조(개인정보의 처리목적)<br/><br/>[코딩티딩](이)가 개인정보 보호법 제32조에 따라 등록․공개하는 개인정보파일의 처리목적은 다음과 같습니다.</strong></p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'>1. 개인정보 파일명 : Ai Teading 개인정보파일</li>
            <li>개인정보의 처리목적 : 프로그램 사용을 위한 정보 수집</li>
            <li>수집방법 : 생성정보 수집 툴을 통한 수집</li>
            <li>보유근거 : 프로그램 사용자의 동의</li>
            <li>보유기간 : 지체없이 파기</li>
            <!--<li>관련법령 :</li>-->
          </ul>
          <br/><br/>
          <p class='lh6 bs4'><strong>제2조(개인정보 영향평가 수행결과)</strong></p>
          <p class='ls2'><br/>없음</p><br/><br/>
          <p class='lh6 bs4'><strong>제3조(개인정보의 제3자 제공에 관한 사항)</strong><br/><br/> ① <em class='emphasis'>[코딩티딩]</em>은 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조
            및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
          <p class='sub_p mgt10'>② <span class='colorLightBlue'>[코딩티딩]</span>은 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'>1. [코딩티딩]</li>
            <li>개인정보를 제공받는 자 : 코딩티딩</li>
            <li>제공받는 자의 개인정보 이용목적 : 서비스 이용 기록, 접속 로그, 쿠키</li>
            <li>제공받는 자의 보유.이용기간: 목적 달성 후 즉시 파기</li>
          </ul>
          <br/><br/><p class='lh6 bs4'><strong>제4조(개인정보의 파기절차 및 파기방법)</strong></p>
          <p class='ls2'><br/>① [코딩티딩]은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br/><br/>② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
            보관장소를 달리하여 보존합니다.<br/>1. 법령 근거 :<br/>2. 보존하는 개인정보 항목 : 접속정보, 사용내역<br/><br/>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br/>1. 파기절차<br/> [코딩티딩]은 파기 사유가 발생한 개인정보를 선정하고, [코딩티딩] 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.<br/></p>
          <p class='sub_p mgt10'>2. 파기방법</p>
          <p class='sub_p'>전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다</p>
          <p class='lh6 bs4'><strong>제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</strong></p>
          <p class='ls2'><br/>① 정보주체는 코딩티딩에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
          <p class='sub_p'>② 제1항에 따른 권리 행사는코딩티딩에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 코딩티딩은 이에 대해 지체 없이 조치하겠습니다.</p>
          <p class='sub_p'>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</p>
          <p class='sub_p'>④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</p>
          <p class='sub_p'>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p>
          <p class='sub_p'>⑥ 코딩티딩은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p><p class='lh6 bs4'><strong>제6조(개인정보의 안전성 확보조치에 관한 사항)<em class='emphasis'><br/><br/>[코딩티딩]</em>은 개인정보의
          안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</strong></p>
          <p class='sub_p mgt10'>1. 접속기록의 보관 및 위변조 방지<br/> 개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다.<br/>또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고
            있습니다.<br/><br/>2. 개인정보에 대한 접근 제한<br/> 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br/><br/></p><br/><br/><p class='lh6 bs4'><strong>제7조(개인정보를 자동으로 수집하는
          장치의 설치·운영 및 그 거부에 관한 사항)</strong></p>
          <p class='ls2'>
            <br/>① 코딩티딩은 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 쿠키(cookie)를 사용합니다.<br/><br/>② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.<br/>가. 쿠키의 사용 목적 : 이용자가
            방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br/>나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.<br/>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
          </p>
          <p class='sub_p mgt30 bs4'><strong>제8조 (개인정보 보호책임자에 관한 사항) </strong><br/><br/></p>
          <p class='sub_p mgt10'> ① <span class='colorLightBlue'>코딩티딩</span>은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'>▶ 개인정보 보호책임자</li>
            <li>성명 : 코딩티딩</li>
            <li>직책 : 없음</li>
            <li>직급 : 없음</li>
            <li>연락처 : codingteading@gmail.com</li>
          </ul>

          <p class='sub_p'><br/>② 정보주체께서는 코딩티딩 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 코딩티딩은 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p>

          <p class='sub_p mgt30 bs4'><strong>제9조(개인정보의 열람청구를 접수·처리하는 부서)</strong><br/><br/> 정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.<br/>[코딩티딩]은 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</p>
          <ul class='list_indent2 mgt10'>
            <li class='tt'>▶ 개인정보 열람청구 접수·처리 부서</li>
            <li>담당자 : 코딩티딩</li>
            <li>연락처 : codingteading@gmail.com</li>
          </ul>

          <p class='lh6 bs4'><strong>제10조(정보주체의 권익침해에 대한 구제방법)<em class='emphasis'></em></strong></p><br/>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는
          아래의 기관에 문의하시기 바랍니다.<br/>
          1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)<br/>
          2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)<br/>
          3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)<br/>
          4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)<br/><br/>

          「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.<br/><br/>


          ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.<br/><br/>
          <p class='lh6 bs4'><strong>제11조(영상정보처리기기 운영·관리에 관한 사항)<br/><br/>① <em class='emphasis'>[코딩티딩]</em>은 아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.</strong></p>
          <p class='sub_p mgt10'>없음</p>

          <p class='lh6 bs4'><strong>제12조(개인정보 처리방침 변경)<em class='emphasis'></em></strong><br/><br/></p><br/><p class='sub_p'>① 이 개인정보처리방침은 2023년 1월 1부터 적용됩니다.</p>
          <p class='sub_p'></p>
          <p class='sub_p'></p>
          <p class='sub_p'>② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다. </p>
          <p class='sub_p'>없음</p>
        </div>
      </div>
      <!-- end title -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  data: function() {
    return {}
  },
  created() {
    $('html, body').animate({
      scrollTop: 0
    }, 250)
  }
}
</script>
